import Vue from 'vue'
import App from './App.vue'
import '../public/css/animate.min.css'
import '../public/css/flex.css'
import '../public/css/index.css'
import '../public/css/reset.css'
import './utils/index.js'
import Antd from './utils/antd.js'
import '../public/css/antd.css'
Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
    <GsLauout></GsLauout>
  </div>
</template>

<script>
import GsLayout from './views/Gs-Lauout'
import GsLauout from './views/Gs-Lauout.vue'
export default {
  name: 'App',
  components: {
    GsLayout,
    GsLauout,
  },
}
</script>

<style lang="scss"></style>

<template>
  <div>
    <header class="header-area">
      <div class="bannerSize"></div>
    </header>
    <main class="subject-style h3000 main-area">
      <!-- 学院优势 -start -->
      <div class="">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="color-fff title">学院优势</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="introduce block-radius color-fff d-flex flex-column align-content-around animate__animated animate__tada">
          <div class="display-box">
            <div>
              <span>办学历史</span>
              <span class="txt-sape">30年专注于培养IT互联网技术</span>
            </div>
            <div>
              <span>学院特色</span>
              <span class="txt-sape">成人成才同步教育</span>
            </div>
            <div>
              <span>教学方法</span>
              <span class="txt-sape">创新五步教学法</span>
            </div>
            <div>
              <span>教学过程</span>
              <span class="txt-sape">项目贯穿</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 学院优势 -end -->
      <!-- 专业设置 -start -->
      <div class="specialtySet-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">专业设置</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="overflow-hidden career-box">
          <ul class="career-ul d-flex caree-wrap flex-wrap d-flex justify-content-between">
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career1.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">软件开发专业</h3>
                <p class="info custom-h"><span class="font-bold">就业方向 ：</span> 软件开发工程师、JAVA工程师、移动开发、安卓开发、电商、通信系统的管理。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career2.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">软件测试专业</h3>
                <p class="info custom-h"><span class="font-bold">就业方向 ：</span>自动化测试、性能测试、WEB自动化测试、移动APP测试、计算机运维、安全渗透测试。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career3.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">大数据开发专业</h3>
                <p class="info"><span class="font-bold">就业方向 ：</span>大数据开发工程师、数据分析师、数据采集、数据整理、数据分析、数据安全。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career4.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">移动智能开发专业</h3>
                <p class="info"><span class="font-bold">就业方向 ：</span>移动应用开发、H5全栈开发工程师、混合式App开发、APP界面设计、系统维护。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career5.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">跨境电商专业</h3>
                <p class="info"><span class="font-bold">就业方向 ：</span> 跨境电子商务平台运营、网络营销与活动策划、淘宝运营、天猫运营、新媒体运营师。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career6.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">VR虚拟现实专业</h3>
                <p class="info custom-h"><span class="font-bold">就业方向 ：</span>项目设计、项目交互工程开发、模型和动画制作、软硬件平台搭建和维护。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career7.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">视觉设计专业</h3>
                <p class="info"><span class="font-bold">就业方向 ：</span>WUI设计、全栈设计、C4D电商场景搭建、店铺设计、品牌设计、移动设备界面设计。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
            <li class="career-item block-radius">
              <a>
                <img src="../../src/assets/images/career8.webp" alt="" width="100%" />
              </a>
              <div class="career-details justify-content-center">
                <h3 class="career-title">WEB架构专业</h3>
                <p class="info"><span class="font-bold">就业方向 ：</span>web架构师、web前端工程师、电商整站开发、商城APP开发、企业APP数据流管理与实战。</p>
                <div class="fs-18 time-pd d-flex justify-content-around">
                  <span class="time-limit">两年/四年制</span>
                  <span class="signed">校企合作</span>
                </div>
                <div class="d-flex justify-content-around btn-box">
                  <button class="details-btn c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">专业详情</a>
                  </button>
                  <button class="cost-Consult c-btn">
                    <a href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">学费咨询</a>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 专业设置 -end -->
      <!-- 视频介绍 -start -->
      <div class="video-introduce">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">视频介绍</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <video
          src="https://bjbuba.oss-cn-beijing.aliyuncs.com/media/%E5%8C%97%E4%BA%AC%E5%B7%A5%E5%95%86%E7%AE%A1%E7%90%86%E4%B8%93%E4%BF%AE%E5%AD%A6%E9%99%A2%E7%AE%80%E4%BB%8B480.4a79c069.mp4"
          width="100%"
          height="100%"
          preload
          controls
          poster="../../src/assets/images/视频封面.webp"
        ></video>
      </div>
      <!-- 视频介绍 -end -->
      <!-- 就业服务 -start -->
      <div class="ensure-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">就业服务</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="content layout-border">
          <div class="top-area layout-radius-top">
            <div>
              <div class="chaar-mr">
                <img src="../../src/assets/images/data1.png" alt="" class="data-chart" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 就业保障 -start -->
      <!-- 6大理由 -start -->
      <div class="reason-area layout-border">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">6大理由</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="content layout-radius-bom h-pd">
          <div>
            <img src="../../src/assets/images/data2.png" alt="" class="banner2" />
            <!-- <div class="teach-block d-flex justify-content-around pb-1">
              <div class="radius-one"></div>
              <div class="radius-two"></div>
              <div class="radius-three"></div>
              <div class="radius-four"></div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- 6大理由 -end -->
      <!-- 教育计划 -start -->
      <div class="teach-plan">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">教育计划</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="">
          <div class="bg-fff plan-block layout-radius-top pic-box position-relative">
            <div class="w-100 d-flex justify-content-between flex-wrap">
              <div class="photos-one pic-49 plan-t position-relative">
                <div class="box-shadow text-center">价值观</div>
              </div>
              <div class="photos-two pic-49 plan-t position-relative">
                <div class="box-shadow text-center">为人处世</div>
              </div>
              <div class="photos-three pic-49 plan-t position-relative">
                <div class="box-shadow text-center">职场礼仪</div>
              </div>
              <div class="photos-four pic-49 plan-t position-relative">
                <div class="box-shadow text-center">执行力</div>
              </div>
            </div>
          </div>
          <p class="color-fff info-txt layout-radius-bom out-pd content">
            “ 教育计划 ” 塑造完善人格,
            通过《校园文化》、《传统文化》、《职场文化》、《精神文化》等学习，使学生具备成熟的心智、健康的人格、独立的思想，使学生成为合格的学校人、合格的社会人、合格的职业人、合格的接班人
          </p>
        </div>
      </div>
      <!-- 教育计划 -end -->

      <!-- 教学计划  -start-->
      <div class="">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">教学计划</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="">
          <div class="bg-fff layout-radius-top pic-box">
            <div class="d-flex flex-wrap">
              <div class="direct pic-50 position-relative">
                <div class="box-shadow">教师指导</div>
              </div>
              <div class="practice pic-50 position-relative">
                <div class="box-shadow">课堂练习</div>
              </div>
              <div class="expand pic-50 position-relative">
                <div class="box-shadow">知识扩充</div>
              </div>
              <div class="analyse pic-50 position-relative">
                <div class="box-shadow">案例分析</div>
              </div>
            </div>
          </div>
          <p class="color-fff info-txt layout-radius-bom out-pd content">
            创新五步教学法，以激发和引导学生感兴趣学习，按照企业岗位需求的标准作为培养目标，逆向倒推分阶段到每个月，确定每个月目标后，将企业岗位所需的行业知识，工作规范，项目经验，职业素养分解到每一天的课程里，就完成了学生到职业人的转变
          </p>
        </div>
      </div>
      <!-- 教学计划  -end-->
      <!-- 师资力量 -start -->
      <div class="faculty-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">师资力量</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="top-pd content layout-radius-top">
          <div class="">
            <div class="d-flex height">
              <div class="teacherInfo-item w-33 border-r text-center size-24 color-fff">
                <div class="head">
                  <span class="countPct">75%</span>
                  高学历
                  <span class="solid"></span>
                </div>
                <div class="duty">博士、硕士研究生、985、211</div>
              </div>
              <div class="teacherInfo-item w-33 border-r border-l text-center size-24 color-fff">
                <div class="head">
                  <span class="countPct">58%</span>
                  大厂背景
                  <span class="solid"></span>
                </div>
                <div class="duty">华为、百度、阿里巴巴、腾讯、IBM</div>
              </div>
              <div class="teacherInfo-item w-33 border-l text-center size-24 color-fff">
                <div class="head">
                  <span class="countPct">82%</span>
                  高职位
                  <span class="solid"></span>
                </div>
                <div class="duty">曾担任企业主管、项目总监、项目经理</div>
              </div>
            </div>
            <div class="d-flex bom-pd">
              <div class="teacherInfo-item box-pd border-r border-t w-50 text-center size-24 color-fff">
                <div class="head">
                  <span class="countPct">36</span>
                  人
                  <span class="solid"></span>
                </div>
                <div class="dutybox">专职课研团队, 负责课程 体系研发, 项目制定</div>
              </div>
              <div class="teacherInfo-item box-pd border-l border-t w-50 text-center size-24 color-fff">
                <div class="head">
                  <span class="countPct small">310</span>
                  人
                  <span class="solid"></span>
                </div>
                <div class="dutybox">专职教学团队, 负责将专业技能倾囊相授</div>
              </div>
            </div>
            <div class="bay-pic">
              <ul class="d-flex flex-wrap justify-content-between day-ul" ref="day-ul">
                <div class="d-flex">
                  <li class="pic-item"><img src="../../src/assets/images/teacher1.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher2.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher3.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher4.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher5.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher6.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher7.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher8.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher9.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher10.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher11.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher12.webp" alt="" /></li>

                  <li class="pic-item"><img src="../../src/assets/images/teacher1.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher2.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher3.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher4.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher5.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher6.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher7.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher8.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher9.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher10.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher11.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher12.webp" alt="" /></li>
                </div>
                <div class="d-flex">
                  <li class="pic-item"><img src="../../src/assets/images/teacher13.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher14.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher15.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher16.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher17.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher18.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher19.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher20.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher21.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher22.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher23.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher24.webp" alt="" /></li>

                  <li class="pic-item"><img src="../../src/assets/images/teacher13.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher14.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher15.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher16.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher17.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher18.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher19.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher20.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher21.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher22.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher23.webp" alt="" /></li>
                  <li class="pic-item"><img src="../../src/assets/images/teacher24.webp" alt="" /></li>

                  <!-- dda -->
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <!-- 师资力量 -end -->
      <!-- 实训中心 -start -->
      <div>
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">实训中心</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="layout-border content">
          <div class="banner_big top-radius">
            <img src="../../src/assets/images/training1.webp" alt="" />
            <div class="box-shadow">数字媒体实训室</div>
          </div>
          <div class="content layout-radius-bom">
            <div class="subject-style top">
              <div class="d-flex justify-content-around size-24 content">
                <div class="mobile theatre training">
                  <div class="box-shadow">移动学院实训室</div>
                </div>
                <div class="software theatre training">
                  <div class="box-shadow">软工学院实训室</div>
                </div>
                <div class="data theatre training">
                  <div class="box-shadow">数据云学院实训室</div>
                </div>
              </div>
            </div>
            <p class="color-fff size-24 out-pd layout-radius-bom">
              SEEC工作室（大学生模拟企业实训室），学生毕业前5个月进入工作室，实训室完全按照企业真实场景搭建，有企业真实线上项目、人员岗位配备、项目流程、部门组成等、实现产教融合无缝对接
            </p>
          </div>
        </div>
      </div>
      <!-- 实训中心 -end -->
      <!-- 技能竞赛 -start -->
      <div class="race-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">技能竞赛</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="layout-border content">
          <div class="race-pd">
            <div class="race-banner-one"></div>
            <div class="d-flex">
              <img src="../../src/assets/images/match.webp" alt="" width="50%" />
              <img src="../../src/assets/images/match2.webp" alt="" width="50%" />
            </div>
          </div>
          <div class="color-fff size-24 race-box">
            <p class="race-txt">2021年第七届中国国际“ 互联网 +”大学生创新创业大赛中荣获二等奖</p>
            <p class="race-txt">2020年第六届中国国际“ 互联网+”大学生创新创业大赛中荣获二、三等奖。</p>
          </div>
          <div class="race-pd">
            <div class="race-banner-two"></div>
            <div class="d-flex">
              <img src="../../src/assets/images/match5.webp" alt="" width="50%" />
              <img src="../../src/assets/images/match6.webp" alt="" width="50%" />
            </div>
          </div>
          <div class="color-fff size-24 award-box">
            <p class="award-txt txt-zxd"><span>2018</span>年北京大学生计算机应用竞赛中荣获三等奖。</p>
            <p class="award-txt"><span>2021</span>年北京大学生计算机应用竞赛中荣获三等奖。</p>
          </div>
        </div>
      </div>
      <!-- 技能竞赛 -end -->
      <!-- 火热报名 -start -->
      <div class="hot-enroll">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">火热报名</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="layout-border">
          <table class="flex overflow-hidden">
            <tr class="tr-left">
              <td class="tr-left line0">
                <div class="pic-size86">
                  <img src="../../src/assets/images/enroll1.webp" alt="" />
                </div>
                <div class="pic-size83">
                  <img src="../../src/assets/images/enroll4.webp" alt="" />
                </div>
                <div class="pic-size69">
                  <img src="../../src/assets/images/enroll7.webp" alt="" />
                </div>
                <div class="pic-size77">
                  <img src="../../src/assets/images/enroll11.webp" alt="" />
                </div>
                <div>
                  <img src="../../src/assets/images/enroll15.webp" alt="" />
                </div>
              </td>
              <td class="tr-center line0">
                <div class="pic-size86">
                  <img src="../../src/assets/images/enroll12.webp" alt="" />
                </div>
                <div class="pic-size83">
                  <img src="../../src/assets/images/enroll5.webp" alt="" />
                </div>
                <div class="pic-size69">
                  <img src="../../src/assets/images/enroll8.webp" alt="" />
                </div>
                <div class="pic-size77">
                  <img src="../../src/assets/images/enroll12.webp" alt="" />
                </div>
                <div>
                  <img src="../../src/assets/images/enroll14.webp" alt="" />
                </div>
              </td>
              <td class="tr-right line0">
                <div class="pic-size86">
                  <img src="../../src/assets/images/enroll3.webp" alt="" />
                </div>
                <div class="pic-size83">
                  <img src="../../src/assets/images/enroll6.webp" alt="" />
                </div>
                <div class="pic-size69">
                  <img src="../../src/assets/images/enroll9.webp" alt="" />
                </div>
                <div>
                  <div class="pic-size44">
                    <img src="../../src/assets/images/enroll10.webp" alt="" />
                  </div>
                  <div class="pic-size64">
                    <img src="../../src/assets/images/enroll13.webp" alt="" />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 火热报名 -end -->
      <!-- 校园环境 -start -->
      <div class="scholl-Scenic">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">校园环境</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="layout-border scenic-box bg-fff">
          <div class="school-ul">
            <img src="../../src/assets/images/school1.webp" alt="" />
            <img src="../../src/assets/images/school2.webp" alt="" />
            <img src="../../src/assets/images/school3.webp" alt="" />
            <img src="../../src/assets/images/school4.webp" alt="" />
            <img src="../../src/assets/images/school5.webp" alt="" />
            <img src="../../src/assets/images/school6.webp" alt="" />
            <img src="../../src/assets/images/school7.webp" alt="" />
            <img src="../../src/assets/images/school8.webp" alt="" />
            <img src="../../src/assets/images/school1.webp" alt="" />
            <img src="../../src/assets/images/school2.webp" alt="" />
          </div>
        </div>
      </div>
      <!-- 校园环境 -end -->
      <!-- 学生活动 -start -->
      <div class="activity-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">学生活动</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="activity-show d-flex justify-content-between flex-wrap">
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity1.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">篮球比赛</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity2.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">过生日</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity3.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">歌唱晚会</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity4.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">叠被子</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity5.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">同学聚会</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity6.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">茶话会</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity7.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">爬长城</div>
          </div>
          <div class="show-item layout-border line0">
            <img src="../../src/assets/images/activity8.webp" alt="" />
            <div class="bg-fff layout-radius-bom text-center size-24 line-h">军训比赛</div>
          </div>
        </div>
      </div>
      <!-- 学生活动 -end -->
      <!-- 学院荣誉 -start -->
      <div class="honor-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">学院荣誉</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>
        <div class="layout-border honor-box content overflow-hidden">
          <div class="honor-ul d-flex" ref="honor-ul">
            <img src="../../src/assets/images/prize1.webp" alt="" class="honor-img" />
            <img src="../../src/assets/images/prize2.webp" alt="" class="honor-img" />
            <img src="../../src/assets/images/prize3.webp" alt="" class="honor-img" />
            <img src="../../src/assets/images/prize1.webp" alt="" class="honor-img" />
            <img src="../../src/assets/images/prize2.webp" alt="" class="honor-img" />
            <img src="../../src/assets/images/prize3.webp" alt="" class="honor-img" />
          </div>
        </div>
      </div>
      <!-- 学院荣誉 -end -->

      <!-- 在线报名 -end -->
      <div class="report-area">
        <div class="text-center title-spacing">
          <div class="d-flex justify-content-center align-items-center">
            <span class="left_con d-block">
              <img src="../../src/assets/images/left_con.webp" alt="" class="icon" />
            </span>
            <h1 class="title color-fff">在线报名</h1>
            <span class="right_con d-block">
              <img src="../../src/assets/images/right_con.webp" alt="" class="icon" />
            </span>
          </div>
        </div>

        <div class="enroll-info hx">
          <form action="http://www.bjbuba.cn/plus/diy.php" enctype="multipart/form-data" method="post">
            <input type="hidden" name="action" value="post" />
            <input type="hidden" name="diyid" value="3" />
            <input type="hidden" name="do" value="2" />
            <div>
              <input type="text" name="name" id="name" value="" placeholder="学生姓名" class="mb-23" />
            </div>
            <div>
              <input type="text" name="phone" id="phone" value="" placeholder="联系方式" class="mb-23" />
            </div>
            <div>
              <input type="hidden" name="time" id="time" />
              <input type="hidden" name="dede_fields" value="name,text;phone,text;time,text" />
              <input type="hidden" name="dede_fieldshash" value="1c0e0d44b0a7681e5aa290a391e9208b" />
              <button type="submit" value="" class="sh_buttom enroll-btn content text-center color-fff layout-border">确认报名</button>
            </div>
          </form>

          <!-- <div class="block mb-23" id="cascader-box">
            <a-cascader :options="options" placeholder="城市" @change="onChange" />
          </div>
          <input type="text" placeholder="姓名" class="mb-23" />
          <input type="text" placeholder="手机号" class="mb-23" />
          <button class="enroll-btn content text-center color-fff layout-border">确认报名</button> -->
        </div>
      </div>

      <!-- 报名表单 -->
      <!-- <form action="http://www.bjbuba.cn/plus/diy.php" enctype="multipart/form-data" method="post">
        <input type="hidden" name="action" value="post" />
        <input type="hidden" name="diyid" value="3" />
        <input type="hidden" name="do" value="2" />
        <div>
          <input type="text" name="name" id="name" value="" placeholder="学生姓名" />
        </div>
        <div>
          <input type="text" name="phone" id="phone" value="" placeholder="联系方式" />
        </div>
        <div>
          <input type="hidden" name="time" id="time" />
          <input type="hidden" name="dede_fields" value="name,text;phone,text;time,text" />
          <input type="hidden" name="dede_fieldshash" value="1c0e0d44b0a7681e5aa290a391e9208b" />
          <button type="submit" value="" class="sh_buttom">确认报名</button>
        </div>
      </form> -->

      <!-- <div class="block mb-23" id="cascader-box">
            <a-cascader :options="options" placeholder="城市" @change="onChange" />
          </div> -->

      <!-- 在线报名 -end -->
    </main>
    <footer class="d-flex">
      <button class="btn-left" @click="playPhone">拨打电话</button>
      <a class="btn-right" href="javascript:void(0)" onclick="openJesongChatByGroup(36857,70264);return false;">点击咨询</a>
    </footer>
  </div>
</template>

<script>
import { arr as data } from '../utils/data.js'
import autoPlayImg from '../utils/cascader'
export default {
  name: 'Gs-Layout',
  data() {
    return {
      options: data,
    }
  },
  methods: {
    onChange(val) {
      console.log(val) // 选中元素数组
    },
    // 跳转手机电话API
    playPhone() {
      window.location.href = 'tel:185-1995-9708'
    },
    // 跳转官网
    // handleConsult() {
    //   doyoo.util.openChat('g=10052662');
    //   window.location.href = '//chat.looyuoms.com/chat/chat/p.do?c=20000491&f=10047868&g=10052662'
    //   return false;
    // },
  },
  mounted() {
    let day_ul = this.$refs['day-ul']
    let honor_ul = this.$refs['honor-ul']
    autoPlayImg(day_ul, 17.7996)
    autoPlayImg(honor_ul, 8.9091)

    // 表单相关
    var nowDate = new Date()
    var str = nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':' + nowDate.getMinutes() + ':' + nowDate.getSeconds()
    document.getElementById('time').value = str
  },
}
</script>

